.lazy-images-container {
  cursor: all-scroll;
  margin-left: 10%;
  margin-right: 10%;
}

.lazy-images-container a {
  cursor: all-scroll;
}

.lazy-images {
  display: flex;
  overflow-y: scroll;
}

.lazy-images::-webkit-scrollbar {
  display: none;
}

.lazy-image {
  width: auto;
  height: 30vh;
  margin: 1rem 5px 1rem 5px;
  opacity: 0;
  transition: 0.5s;
}

.lazy-image.show {
  opacity: 1;
  transition: 0.5s;
}

.lazy-image:hover {
  scale: 1.05;
}
