.dropdown {
  width: 300px;
  margin-bottom: 2rem;
  border-radius: 10px;
  box-shadow: 0px 6px 0px rgba(0, 0, 0, 1);
  background-color: white;
  transition: 0.1s;
}
.dropdown:active {
  box-shadow: 0px 3px 0px rgba(0, 0, 0, 1);
}

.dropdown-header {
  padding: 15px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdown-body {
  padding: 5px;
  border-top: 1px solid #e5e8ec;
  display: none;
}

.dropdown-body.open {
  display: block;
}

.dropdown-item {
  padding: 10px;
  transition: 0.3s;
}

.dropdown-item:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.1);
  transition: 0.3s;
}

.dropdown-item-dot {
  opacity: 0;
  color: #91a5be;
  transition: all 0.2s ease-in-out;
}

.dropdown-item-dot.selected {
  opacity: 1;
}

.icon {
  transform: rotate(0deg);
  transition: all 0.2s ease-in-out;
}

.icon.open {
  transform: rotate(90deg);
}

.font-bold {
  font-weight: bolder;
}

.dropdown-createNew {
  padding: 10px;
}

.createNew {
  display: flex;
  align-items: center;
}
.createNew .createNew_input {
  margin-left: 0.5rem;
}
.createNew .icon_add {
  margin-left: 1rem;
  transition: 0.3s;
}
.createNew .icon_add:hover {
  cursor: pointer;
  scale: 1.1;
  transition: 0.3s;
}
