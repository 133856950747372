.panel {
  margin: 30px auto 30px;
  max-width: 500px;
  text-align: center;
}
.button_outer {
  border-radius: 10px;
  box-shadow: 0px 6px 0px rgba(0, 0, 0, 1);
  background: #fff;
  text-align: center;
  height: 50px;
  width: 200px;
  display: inline-block;
  transition: 0.1s;
  position: relative;
  overflow: hidden;
}
.button_outer:active {
  box-shadow: 0px 3px 0px rgba(0, 0, 0, 1);
}

.btn_upload {
  padding: 17px 30px 12px;
  font-weight: 100;
  color: black;
  text-align: center;
  position: relative;
  display: inline-block;
  overflow: hidden;
  z-index: 3;
  white-space: nowrap;
}
.btn_upload input {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  width: 100%;
  height: 105%;
  cursor: pointer;
  opacity: 0;
}
.file_uploading {
  width: 100%;
  height: 10px;
  margin-top: 20px;
  background: #ccc;
  box-shadow: none;
}
.file_uploading .btn_upload {
  display: none;
}
.processing_bar {
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 100%;
  background: black;
  transition: 3s;
}
.file_uploading .processing_bar {
  width: 100%;
}

.file_uploaded .success_box {
  display: inline-block;
}
.file_uploaded {
  margin-top: 0;
}
.uploaded_file_view {
  max-width: 100px;
  margin: 40px auto;
  text-align: center;
  position: relative;
  transition: 0.2s;
  opacity: 0;
  padding: 15px;
}
.file_remove {
  width: 30px;
  height: 30px;
  display: block;
  position: absolute;
  background: #aaa;
  line-height: 30px;
  color: #fff;
  font-size: 12px;
  cursor: pointer;
  right: -15px;
  top: -15px;
}
.file_remove:hover {
  background: #222;
  transition: 0.2s;
}
.uploaded_file_view img {
  max-width: 100%;
}
.uploaded_file_view.show {
  opacity: 1;
}
.error_msg {
  text-align: center;
  color: #f00;
}
