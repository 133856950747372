:root {
  --mainColor: #000099;
  --themeColor: #222;
  --mainFontColor: var(--themeColor);
  --lightFontColor: lightgray;
  --warningColor: #bc4749;
  --backgroundColor: rgba(255, 255, 255, 0.7);
  --backgroundColorDark: var(--themeColor);
  --secondFontColor: gray;
  --animationFactor: 10%;
  --workCardSize: 250px;
  --workImageSize: 200px;
  --websiteNameSize: 1.5rem;
  --headerWidth: 20rem;
  --fontSize_h1: 10rem;
  --fontSize_h2: 2rem;
  --fontSize_h4: 1.5rem;
  --fontSize_p: 1rem;
  --imgSize_A: 20rem;
  --textFrame_width: 30rem;
  --transitionTime: 0.4s;
  --mediaMaxWidth: 50vw;
}

.hidden {
  display: none;
}

* {
  box-sizing: border-box;
}
a {
  cursor: pointer;
}
html {
}
body {
  color: var(--themeColor);
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
}

main {
  margin: 0px 0px;
}
img {
  animation: fadeIn 1s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.imageLayer__1NyU2 {
  animation: none !important;
}
header {
  display: flex;
  justify-content: space-between;
  margin: 1rem;
  align-items: center;
}

header .atelierZZZ {
  display: flex;
}
header #atelier {
  font-size: var(--websiteNameSize);
  font-weight: 100;
}
.ZZZ {
  display: flex;
}

.ZZZ h1 {
  font-size: var(--websiteNameSize);
  color: var(--themeColor);
}

.ZZZ h1:nth-child(1) {
  color: transparent;
}

.ZZZ h1:nth-child(2) {
  color: var(--themeColor);
  animation: animate 4s ease-in-out infinite;
  transform: translate(-100%, 0%);
}

@keyframes animate {
  0%,
  100% {
    clip-path: polygon(
      0% calc(40% - 1.1 * var(--animationFactor)),
      15% calc(40% - 1.3 * var(--animationFactor)),
      30% calc(40% - 1.5 * var(--animationFactor)),
      45% calc(40% - 1.7 * var(--animationFactor)),
      60% calc(40% + 1.5 * var(--animationFactor)),
      75% calc(40% + 1.3 * var(--animationFactor)),
      100% calc(40% + 1.1 * var(--animationFactor)),
      100% 100%,
      0% 100%
    );
  }

  50% {
    clip-path: polygon(
      0% calc(40% + 1.1 * var(--animationFactor)),
      15% calc(40% + 1.3 * var(--animationFactor)),
      30% calc(40% + 1.5 * var(--animationFactor)),
      45% calc(40% + 1.7 * var(--animationFactor)),
      60% calc(40% - 1.5 * var(--animationFactor)),
      75% calc(40% - 1.3 * var(--animationFactor)),
      100% calc(40% - 1.1 * var(--animationFactor)),
      100% 100%,
      0% 100%
    );
  }
}

header a {
  text-decoration: none;
  color: inherit;
}
header a.logo {
  font-weight: bold;
  font-size: 1.5rem;
}
header nav {
  display: flex;
  gap: 1rem;
}

header nav .header_button {
  margin-left: 1rem;
}

header nav #function_buttons {
  position: absolute;
  display: flex;
  right: 5.5rem;
  top: 1.9rem;
}
header nav .header_button_ui {
  border-radius: 10%;
  padding: 0.5rem;
  transition: 0.2s;
}
header nav .header_button_ui:hover {
  box-shadow: 5px 5px 0 rgb(0, 0, 0, 1);
}

#header_buttons.hide {
  visibility: hidden;
}

header nav .header_button:hover {
  text-decoration: underline;
}
#searchBar {
  align-items: center;
  position: absolute;
  right: 9rem;
  top: 2.3rem;
  display: flex;
}
#searchBar span {
  cursor: pointer;
}
#searchBar input {
  margin: auto;
  width: 9rem;
  height: 2rem;
  border-radius: 1rem;
  transition: 0.2s;
}
#searchBar input:hover {
  border: 2px solid black;
}

#signIn_button {
  position: absolute;
  top: 2.3rem;
  right: 2rem;
  width: 5rem;
  height: 2rem;
  background-color: var(--themeColor);
  border-radius: 1rem;
  display: flex;
  transition: 0.5s;
}

#signIn_button:hover {
  margin-left: 2rem;
  cursor: pointer;
  padding-left: 3rem;
}

#signIn_button span {
  font-size: 2rem;
  color: white;
}

.menu {
  margin: 1rem;
}
.menu ul {
  list-style-type: "+  ";
}

.menu .menu_text {
  color: var(--secondFontColor);
  text-decoration: none;
  transition: 0.5s;
}

.menu .menu_active {
  color: var(--secondFontColor);
  text-decoration: underline;
  color: var(--mainColor);
  margin-left: 1rem;
  display: flex;
  transition: 0.5s;
}

.menu .menu_text:hover {
  color: var(--themeColor);
  text-decoration: underline;
  transition: 0.5s;
}

div.posts {
  justify-items: center;
  align-items: center;
  display: grid;
  gap: 5rem;
  grid-template-columns: 0.33fr 0.33fr 0.33fr;
  margin: 2rem;
}
@media screen and (max-width: 600px) {
  div.posts {
    grid-template-columns: 1fr;
    margin: 0px 2rem;
  }
  header nav {
    display: flex;
    flex-direction: column;
    font-size: 0.8rem;
  }
  header .atelierZZZ {
    display: flex;
    flex-direction: column;
  }
  header #atelier {
    font-size: 1rem;
    margin-top: -1rem;
  }
}
@media screen and (max-width: 1024px) and (min-width: 600px) {
  div.posts {
    grid-template-columns: 0.5fr 0.5fr;
  }
}

div.home_page .profile {
  display: flex;
  max-width: 100%;
  max-height: 60vh;
  margin-left: auto;
  margin-right: auto;
}

div.home_page .Brief {
  padding: 50px;
  display: flex;
  color: var(--themeColor);
  text-decoration: none;
  justify-content: center;
}

div.post .image {
  display: flex;
  flex-direction: column;
  width: 300px;
  margin-top: auto;
  margin-bottom: auto;
  transition: 0.3s;
}
div.post .image:hover {
  transition: 0.3s;
  filter: brightness(0.9);
  transform: scale(1.02);
  /* overflow: hidden; */
}

div.post {
  display: flex;
  flex-direction: column;
  height: 80vh;
  max-width: 400px;
  align-items: center;
}
@media screen and (min-width: 600px) {
  div.post {
    grid-template-columns: 1fr;
  }
}
div.post div.texts {
  display: flex;
  flex-direction: column;
  align-items: center;
}
div.post div.texts h2 {
  margin: 0;
  font-size: 1.2rem !important;
  display: inline-block;
  position: relative;
  color: var(--themeColor);
  text-align: center;
}
div.post div.texts h2::after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: var(--mainColor);
  transform-origin: bottom right;
  transition: transform 0.5s ease-out;
}
div.post div.texts h2:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
div.post div.texts a {
  text-decoration: none;
  color: inherit;
}
div.post div.texts p {
  text-decoration: underline;
  font-style: italic;
}

div.post p.info {
  margin: 24px 0;
  color: var(--lightFontColor);
  font-size: 1rem;
  font-weight: bold;
  display: flex;
  gap: 10px;
}
div.post p.info a.author {
  color: var(--themeColor);
}
div.post p.summary {
  margin: 10px 0px;
  line-height: 1.8rem;
  text-align: justify;
}

form.login,
form.register {
  max-width: 400px;
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
}
.login_assistant_buttons {
  display: flex;
  width: 100%;
  grid-template-columns: 0.2fr;
}
.login_assistant_button {
  display: flex;
  margin-top: 1rem;
  color: var(--themeColor);
  text-decoration: none;
  align-items: center;
  padding: 0.5rem;
  border-radius: 0.25rem;
  transition: 0.2s;
}
.login_assistant_button:hover {
  background-color: var(--lightFontColor);
}

/* social login */
.auth_google {
  display: block;
  margin-top: 2rem;
}
.auth_google p {
  display: flex;
  justify-content: center;
}
.auth_google #signInDiv {
  display: flex;
  justify-content: center;
}

.edit_form {
  margin: 1rem 1rem;
}

#profile_preview img {
  display: flex;
  max-height: 60vh;
  max-width: 100%;
  margin-top: 3rem;
  margin-left: auto;
  margin-right: auto;
}

.edit_form .form_button {
  display: grid;
  grid-template-columns: 0.5fr 0.5fr;
  gap: 0.5rem;
}
input {
  display: block;
  margin-bottom: 5px;
  width: 100%;
  padding: 5px 7px;
  border: 2px solid #ddd;
  border-radius: 5px;
  background-color: #fff;
}
button {
  cursor: pointer;
  width: 100%;
  height: 3rem;
  display: block;
  color: black;
  border-radius: 5px;
  box-shadow: 0 5px 0 rgb(0, 0, 0, 1);
  margin-top: 2rem;
  padding: 7px 0;
  transition: 0.2s;
}
button:hover {
  box-shadow: 0 5px 0 rgb(0, 0, 0, 1);
  background-color: transparent;
  color: black;
}
button:active {
  box-shadow: 0 2px 0 rgb(0, 0, 0, 1);
  background-color: transparent;
  color: black;
  transition: 0.05s;
}
.form_button {
  margin-top: 0px;
}
.negative_button {
  text-decoration: none;
}

form.login h1,
form.register h1 {
  text-align: center;
}

#g_id_onload {
  position: absolute;
  right: 0.5rem;
  top: 4rem;
}

/* Archive title in post page */
div.post-page div.project_name {
  margin-top: 2rem;
  margin-bottom: 4rem;
  display: flex;
  justify-content: center;
  font-size: 2.5rem;
  text-align: center;
}

div.post-page div.project_name span {
  font-weight: bold;
}

div.post-page .project_name_link {
  text-decoration: none;
}
/* Archive title in post page */

/* projectList hover animation */
.project_info {
  margin-bottom: 10rem;
}
.projectList {
  display: flex;
  flex-direction: column;
  position: relative;
  color: var(--themeColor);
}
.projectList .project .project_link {
  text-decoration: none;
  color: var(--themeColor);
}
.archiveList {
  margin-top: 5rem;
}
.projectList .project .project_name {
  font-size: 1.5rem;
  font-weight: 100;
  transition: 0.5s;
  margin-left: 10%;
  margin-right: 10%;
  color: var(--secondFontColor);
}
.projectList .project .project_name:hover {
  transform: translateY(0.5rem);
  transition: 0.5s;
  color: var(--themeColor);
}
.project_info .project_brief {
  margin-left: 10rem;
  margin-right: 10rem;
}

.projectList .project::after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: var(--mainColor);
  transform-origin: bottom right;
  transition: transform 0.5s ease-out;
  color: var(--mainColor);
}
.projectList .project:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
  color: var(--mainColor);
}
/* projectList hover animation */

/* project indexpage */
.indexpage_project_name {
  margin: 2rem 0px 0px 0px;
  padding: 0px 2rem 0px 2rem;
  display: flex;
  text-transform: uppercase;
  font-weight: 100;
  justify-content: flex-end;
  font-size: 2rem;
  position: relative;
  color: var(--themeColor);
}
.indexpage_project_name::after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: var(--mainColor);
  transform-origin: bottom right;
  transition: transform 0.5s ease-out;
  color: var(--mainColor);
}
.indexpage_project_name:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
  color: var(--mainColor);
}
/* project indexpage */

/* project_name hover animation */
div.post-page div.project_name {
  display: flex;
  position: relative;
  color: var(--themeColor);
}
div.post-page div.project_name::after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: var(--mainColor);
  transform-origin: bottom right;
  transition: transform 0.5s ease-out;
  color: var(--mainColor);
}
div.post-page div.project_name:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
  color: var(--mainColor);
}
/* project_name hover animation */

div.post-page div.image {
  max-height: 100%;
  display: flex;
  margin-left: auto;
  margin-right: auto;
}

div.post-page div.image img {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  object-fit: cover;
  object-position: center center;
  width: calc(var(--mediaMaxWidth) * 0.5);
  margin-bottom: 3rem;
}
@media screen and (max-width: 1024px) and (min-width: 300px) {
  div.post-page div.image img {
    width: calc(var(--mediaMaxWidth) * 1);
  }
}
div.post-page a {
  color: #333;
  text-decoration: underline;
}
div.post-page h1 {
  font-size: 1.8rem;
  text-align: center;
  margin: 10px 0 5px;
}
@media screen and (max-width: 1024px) and (min-width: 300px) {
  div.post-page h1 {
    font-size: 1.2rem;
  }
}
div.post-page time {
  text-align: center;
  display: block;
  font-size: 1rem;
  color: #aaa;
  margin: 10px 0;
}
div.post-page div.author {
  text-align: center;
  margin-bottom: 20px;
  font-size: 0.7rem;
  font-weight: bold;
}
div.post-page .content {
  margin-top: 3rem;
  margin-bottom: 3rem;
  /* display: flex; */
  /* flex-direction: column; */
}

div.content {
  width: auto;
  margin: 5vw 5vw;
}

div.content img {
  display: flex;
}

div.content p {
  line-height: 1.7rem;
}
div.content li {
  margin-bottom: 10px;
}
div.content a {
  transition: 0.3s;
}
div.content a:hover {
  transition: 0.3s;
  color: var(--mainColor);
}
div.post div.texts {
  height: 5rem;
  position: relative;
}
div.post div.texts h2 {
  font-size: 1.5rem;
  position: relative;
  color: var(--themeColor);
}
div.post div.texts h2::after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: var(--mainColor);
  transform-origin: bottom right;
  transition: transform 0.5s ease-out;
  color: var(--mainColor);
}
div.post div.texts h2:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
  color: var(--mainColor);
}

div.edit-row {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 20px;
}
@media screen and (max-width: 1024px) and (min-width: 300px) {
  div.edit-row {
    width: 100vw;
    display: flex;
  }
}

div.post-page .edit-btn {
  display: inline-flex;
  font-size: 1rem;
  gap: 5px;
  margin: 3rem;
  color: black;
  padding: 15px 30px;
  text-decoration: none;
}
div.post-page .edit-btn:hover {
  background-color: var(--themeColor);
  color: white;
  transition: 0.3s;
}

div.post-page .delete-btn {
  display: inline-flex;
  font-size: 1rem;
  gap: 5px;
  margin: 3rem;
  color: black;
  padding: 15px 30px;
  text-decoration: none;
}

div.post-page .delete-btn:hover {
  text-decoration: underline;
  color: var(--warningColor);
  transition: 0.3s;
}

a svg {
  height: 20px;
}

#toolbar .ql-formats button {
  margin-top: auto;
  margin-bottom: auto;
  box-shadow: none;
}
.ql-size-extra-small {
  font-size: 12px;
}
.ql-size-small {
  font-size: 14px;
}
.ql-size-medium {
  font-size: 20px;
}
.ql-size-large {
  font-size: 22px;
}

.content .ql-video {
  display: flex;
  width: var(--mediaMaxWidth);
  height: calc(var(--mediaMaxWidth) * 1080 / 1920);
  margin-left: auto;
  margin-right: auto;
}

.content img {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  max-height: 50vh;
  max-width: 80vw;
}
@media screen and (max-width: 1024px) and (min-width: 300px) {
  .content img {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
  }
}
.content .ql-video {
  display: flex;
  width: calc(var(--mediaMaxWidth) * 1.7);
  height: calc(var(--mediaMaxWidth) * 1.7 * 1080 / 1920);
  margin-left: auto;
  margin-right: auto;
}
.content img:hover {
  cursor: zoom-in;
}
.content iframe {
  display: flex;
  width: var(--mediaMaxWidth);
  height: calc(var(--mediaMaxWidth) * 1080 / 1920);
  margin-left: auto;
  margin-right: auto;
}
.content audio {
  margin: 0.5rem;
}
@media screen and (max-width: 1024px) and (min-width: 300px) {
  .content iframe {
    display: flex;
    width: calc(var(--mediaMaxWidth) * 1.7);
    height: calc(var(--mediaMaxWidth) * 1.7 * 1080 / 1920);
    margin-left: auto;
    margin-right: auto;
  }
}
.ql-align-right {
  text-align: right;
}
.ql-align-center {
  text-align: center;
}
.ql-align-left {
  text-align: left;
}
.ql-align-justify {
  text-align: justify;
}

.ql-editor {
  min-height: 40vh;
}
.ql-editor img {
  max-width: 50vw;
  max-height: 50vh;
}

.ql-counter {
  margin: 1rem;
}

figcaption {
  display: flex;
  justify-content: center;
  font-size: 1rem;
  font-style: italic;
  color: #aaa;
}

.home_page .detail {
  display: flex;
  justify-content: center;
}

.arrow {
  background-image: url("https://static-cdn.wdh.gg/assets/arrow_down_dark.svg");
  background-size: contain;
  background-repeat: no-repeat;
}

.link {
  cursor: pointer;
  height: 5rem;
  width: 5rem;
  margin: 0px 0 0 -40px;
  position: absolute;
  left: 50%;
  text-align: center;
  font-size: 70px;
  z-index: 100;
  text-decoration: none;
  animation: move_down 3s ease-in-out infinite;
  scale: 0.5;
}

@keyframes move_down {
  0% {
    transform: translate(0, -20px);
  }
  50% {
    transform: translate(0, 0px);
  }
  100% {
    transform: translate(0, -20px);
  }
}

.turnjs5_iframe {
  display: flex;
  height: 100vh;
}

.turnjs_iframe_inserted {
  width: 100%;
  height: 80vh;
  overflow: hidden;
  animation: show_up 2s;
}

@keyframes show_up {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.booklet_page {
  object-fit: scale-down;
}
.booklet_iframe iframe {
  display: flex;
  margin: auto;
  width: 95%;
  height: 100%;
}

.image-uploading::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30px;
  height: 30px;
  margin-top: -15px;
  margin-left: -15px;
  border-radius: 50%;
  border: 3px solid #ccc;
  border-top-color: var(--themeColor) !important;
  z-index: 1;
  animation: spinner 0.6s linear infinite;
}

/* ql-attachmentUploading */
/* .ql-attachment-blot {
  display: inline-block;
  border-radius: 10px;
  padding: 10px;
  margin: 10px;
  cursor: pointer;
  border: 1px dashed black;
  opacity: 0.8;
  transition: 0.3s;
}

.ql-attachment-blot:hover {
  opacity: 1;
  transition: 0.3s;
}

.ql-attachment-blot a {
  display: flex;
  flex-direction: column;
  text-decoration: none !important;
  align-items: center;
}
.ql-attachment-blot svg {
  display: flex;
  height: 80px;
}

.ql-attachment-blot span {
  display: block;
  margin-top: 10px;
  max-width: 400px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
} */
/* ql-attachmentUploading */

#button_filePond_removeAll {
  color: #555;
  border: none;
  width: 20% !important;
  margin-left: auto;
  box-shadow: 0 5px 0 rgb(0, 0, 0, 1);
  transition: 0.2s;
}
#button_filePond_removeAll:hover {
  background-color: var(--warningColor);
  color: white;
  transition: 0.2s;
}
#button_filePond_removeAll:active {
  box-shadow: 0 2px 0 rgb(0, 0, 0, 1);
}

#zmageImage {
  cursor: crosshair !important;
}
#zmage img {
  animation: none !important;
}
