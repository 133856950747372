.card-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: flex-start;
}

.card {
  background-color: white;
  border: 1px solid black;
  border-radius: 0.25rem;
  padding: 0.5rem;
  transform: translateX(100px);
  opacity: 0;
  transition: 150ms;
}

.card.show {
  transform: translateX(0);
  opacity: 1;
}

.card img {
  height: 200px;
  width: auto;
}
